<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" @change="(event) => updateMachTypeDropdown(event)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="divisionModel" :items="divisionLists" default="" item-value="class" item-text="descr" label="Select Division" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="qualityModel" :items="qualityLists" default="" item-value="grade" item-text="grade" label="Select Quality" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Select Mach. Type" @change="(event) => updateMachIDDropdown(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Select Mach. ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <v-select v-model="periodModel" :items="periodLists" default="" item-value="periode" item-text="periode" label="Select Period" @change="(event) => updateChart1ByPeriod(event)" clearable dense prepend-icon="mdi-calendar-check"></v-select>
                        </div>
                        <div class="col-lg-12">
                            <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph3">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <v-select v-model="periodDateModel" :items="periodDateLists" default="" item-value="date_val" item-text="date" label="Select Date" @change="(event) => updateChart3ByDate(event)" clearable dense prepend-icon="mdi-calendar-check"></v-select>
                        </div>
                        <div class="col-lg-12">
                            <div id="chartContainer3" style="height: 350px; width: 100%;"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 cardGraph4">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer4" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA-Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Quality Performance Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            divisionModel: '',
            divisionLists: [],
            qualityModel: '',
            qualityLists: [],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
            periodModel: '',
            periodLists: [],
            periodDateModel: '',
            periodDateLists: []
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            CanvasJS.addColorSet("colorSet1",[
                "#34626c",
                "#f05454",
                "#af6b58",
                "#c6b497"
            ]);

            CanvasJS.addColorSet("colorSet2",[
                "#0db39e",
                "#efea5a",
                "#f1c453",
                "#f29e4c",
                "#16db93",
                "#83e377",
                "#b9e769",
                "#54478c",
                "#2c699a",
                "#048ba8"
            ]);

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.divisionLists = res.data.division
                this.qualityLists = res.data.quality
                this.machTypeLists = res.data.machType
                this.machIDLists = res.data.machID
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            this.periodLists = []
            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    division: this.divisionModel ? this.divisionModel : '',
                    quality: this.qualityModel ? this.qualityModel : '',
                    machType: this.machTypeModel ? this.machTypeModel : '',
                    machID: this.machIDModel ? this.machIDModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.result2.length != 0){
                    document.querySelector(".cardGraph1").style.display = "block";
                    document.querySelector(".cardGraph2").style.display = "block";
                    this.renderChart1(parseFloat(res.data.pct_prime), parseFloat(res.data.pct_nonprime), parseFloat(res.data.pct_scrap))
                    this.renderChart2(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3)
                    this.periodLists = res.data.result2
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "none";
                    document.querySelector(".cardGraph2").style.display = "none";
                    document.querySelector(".cardGraph3").style.display = "none";
                    document.querySelector(".cardGraph4").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
                
            })

        },

        async getDailyChart(e){


            this.$store.dispatch('setOverlay', true)
            this.periodDateLists = []
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getChartDataDaily`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                quality: this.qualityModel ? this.qualityModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }).then(res => {

                this.$store.dispatch('setOverlay', false)
                this.periodDateLists = res.data.arr_list4
                document.querySelector(".cardGraph3").style.display = "block";
                document.querySelector(".cardGraph4").style.display = "block";
                this.renderChart3(res.data.pct_prime, res.data.pct_nonprime, res.data.pct_scrap)
                this.renderChart4(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3)

            })

        },

        renderChart1(Prime, NonPrime, Scrap){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                colorSet: 'colorSet2',
                animationEnabled: true,
                title: {
                    text: "Production Quality Performance Analysis % (Periodic)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                theme: "light2",
                legend:{
                    cursor: "pointer",
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{
                    type: "pie",
                    showInLegend: true,
                    percentFormatString: "#0.0#",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 11,
                    dataPoints: [
                        {y: Prime, label: "Prime", legendText: "Prime"},
                        {y: NonPrime, label: "NonPrime", legendText: "NonPrime"},
                        {y: Scrap, label: "Scrap", legendText: "Scrap"}
                    ]
                }]
            })

            chart1.render()

        },

        renderChart2(data1, data2, data3){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                colorSet: 'colorSet2',
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Production Quality Performance Analysis (Periodic)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Weight in KG",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "stackedBar",
                        click: this.getDailyChart,
                        name: "Prime",
                        showInLegend: true,
                        dataPoints: data1
                    },
                    {

                        type: "stackedBar",
                        click: this.getDailyChart,
                        name: "NonPrime",
                        showInLegend: true,
                        dataPoints: data2
                    },
                    {

                        type: "stackedBar",
                        click: this.getDailyChart,
                        name: "Scrap",
                        showInLegend: true,
                        dataPoints: data3
                    },
                ]
            });

            chart2.render()

        },

        renderChart3(Prime, NonPrime, Scrap){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                colorSet: 'colorSet1',
                animationEnabled: true,
                title: {
                    text: "Production Quality Performance Analysis % (Daily)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                theme: "light2",
                legend:{
                    cursor: "pointer",
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{
                    type: "pie",
                    showInLegend: true,
                    percentFormatString: "#0.0#",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 11,
                    dataPoints: [
                        {y: Prime, label: "Prime", legendText: "Prime"},
                        {y: NonPrime, label: "NonPrime", legendText: "NonPrime"},
                        {y: Scrap, label: "Scrap", legendText: "Scrap"}
                    ]
                }]
            })

            chart3.render()

        },

        renderChart4(Prime, NonPrime, Scrap){

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                colorSet: 'colorSet1',
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Production Quality Performance Analysis (Daily)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Weight in KG",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "stackedBar",
                        name: "Prime",
                        showInLegend: true,
                        dataPoints: Prime
                    },
                    {

                        type: "stackedBar",
                        name: "NonPrime",
                        showInLegend: true,
                        dataPoints: NonPrime
                    },
                    {

                        type: "stackedBar",
                        name: "Scrap",
                        showInLegend: true,
                        dataPoints: Scrap
                    },
                ]
            })

            chart4.render()

        },

        async updateMachTypeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getMachineByMill?mill=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getMachineByMill?mill=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            }
        },

        async updateMachIDDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getMachineByID?mill=${this.millModel}&machType=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getMachineByID?mill=all&machType=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            }
        },

        async updateChart1ByPeriod(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                var mill = this.millModel ? this.millModel : ''
                var division = this.divisionModel ? this.divisionModel : ''
                var quality = this.qualityModel ? this.qualityModel : ''
                var machType = this.machTypeModel ? this.machTypeModel : ''
                var machID = this.machIDModel ? this.machIDModel : '' 

                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getChart1Byperiod?mill=${mill}&division=${division}&quality=${quality}&machType=${machType}&machID=${machID}&period=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    this.renderChart1(res.data.pct_prime, res.data.pct_nonprime, res.data.pct_scrap)
                })
            }

        },

        async updateChart3ByDate(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                var mill = this.millModel ? this.millModel : ''
                var division = this.divisionModel ? this.divisionModel : ''
                var quality = this.qualityModel ? this.qualityModel : ''
                var machType = this.machTypeModel ? this.machTypeModel : ''
                var machID = this.machIDModel ? this.machIDModel : '' 

                axios.get(`${process.env.VUE_APP_URL}/api/kka/QualityPerformance/getChart3ByDate?mill=${mill}&division=${division}&quality=${quality}&machType=${machType}&machID=${machID}&date=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    this.renderChart3(res.data.pct_prime, res.data.pct_nonprime, res.data.pct_scrap)
                })
            }

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}`;      
            return date;
        }


    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>